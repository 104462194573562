/*
 * 업무구분: 고객
 * 화 면 명: MSPCM320D
 * 화면설명: 관심고객 회수대상
 * 작 성 일: 2022.07.15
 * 작 성 자: 황원준
 */
<template>
  <ur-page-container title="New Page" :show-title="false" @on-scroll-bottom="fn_LoadingData()" ref="page" :topButton="true" :topButtonBottom="topButtonBottom">
    <ur-box-container direction="column" alignV="start">
      <!-- 상단고정 Tab UI -->
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow>
              <div slot="nav" class="icon-wrapper">
                <mo-icon color="white" icon-size="36px" borderless @click="fn_HistoryBack">previous</mo-icon>
              </div>
              <div slot="action" @click="fn_OpenMSPCM116P()">
                <mo-icon borderless>search</mo-icon><!-- icon-size="36px" -->
              </div>
              <!-- 헤더 타이틀 영역 -->
              {{scrollRate > 0.5 ? '관심고객' : '관심고객'}}
              <!-- 헤더 타이틀 영역 //-->
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <div style="height: 1px"></div>
            <div class="ns-swiper-area no-pagination mb15" v-show="hasUnTuch === true && isObj === true">
              <mo-carousel number-of-page="2" ref="swiper" height="auto" :no-rotate="true" :swiper-option="swiperOption" :prevButton="false" :nextButton="false" style="padding:0 24px;">
                <template #1>
                  <div class="ns-banner-box ns-style1">
                    <strong class="title">인수참여</strong>
                    <strong class="title" v-if="hasPtpt">신청이 완료되었습니다.</strong>
                    <strong class="title" v-else>신청 기간입니다.</strong>
                    <div class="txt-btn-box">
                      <mo-button class="ns-btn-round white" v-if="hasPtpt" @click="fn_ConfInsu()">인수참여 취소</mo-button>
                      <mo-button class="ns-btn-round white" v-else @click="fn_Insu('I')">인수참여 신청</mo-button>
                    </div>
                  </div>
                </template>
                <template #2>
                  <div class="ns-banner-box ns-style2">
                    <strong class="title">회수대상</strong>
                    <strong class="title">고객이 있습니다.</strong>
                    <div class="txt-btn-box">
                      <mo-button class="ns-btn-round white">회수제외 신청</mo-button>
                    </div>
                  </div>
                </template>
              </mo-carousel>
            </div>
            <div class="ns-swiper-area no-pagination mb15" v-show="hasUnTuch === false && isObj === true">
              <mo-carousel number-of-page="1" ref="swiper1" height="auto" :no-rotate="true" :swiper-option="swiperOption" :prevButton="false" :nextButton="false" style="padding:0 24px;">
                <template #1>
                  <div class="ns-banner-box ns-style1">
                    <strong class="title">인수참여</strong>
                    <strong class="title" v-if="hasPtpt">신청이 완료되었습니다.</strong>
                    <strong class="title" v-else>신청 기간입니다.</strong>
                    <div class="txt-btn-box">
                      <mo-button class="ns-btn-round white" v-if="hasPtpt" @click="fn_ConfInsu()">인수참여 취소</mo-button>
                      <mo-button class="ns-btn-round white" v-else @click="fn_Insu('I')">인수참여 신청</mo-button>
                    </div>
                  </div>
                </template>
              </mo-carousel>
            </div>
            <div class="ns-swiper-area no-pagination mb15" v-show="hasUnTuch === true && isObj === false">
              <mo-carousel number-of-page="1" ref="swiper2" height="auto" :no-rotate="true" :swiper-option="swiperOption" :prevButton="false" :nextButton="false" style="padding:0 24px;">
                <template #1>
                  <div class="ns-banner-box ns-style2">
                    <strong class="title">회수대상</strong>
                    <strong class="title">고객이 있습니다.</strong>
                    <div class="txt-btn-box">
                      <mo-button class="ns-btn-round white">회수제외 신청</mo-button>
                    </div>
                  </div>
                </template>
              </mo-carousel>
            </div>
          </template>

          <!-- stickey 영역 -->
          <template #sticky>
            <mo-tab-box default-idx="2" primary class="ns-move-tab-box">
              <!-- tab id loop -->
              <mo-tab-label idx="1" @click="$router.push({name: 'MSPCM317M', params: null})">전체</mo-tab-label>
              <mo-tab-label idx="2">회수대상</mo-tab-label>
              <!-- tab id loop //-->
            </mo-tab-box>

            <!-- 전체체크 -->
            <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-sum">
              <mo-list-item>
                <mo-checkbox v-model="isOneOrMoreCheck" @click="fn_CheckAll()" :disabled="checkDisabled || isEmptyView"></mo-checkbox>
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <span class="sum">{{ checkCount > 0 ? checkCount + '명 선택' : '총 ' + totCnt + '명' }}</span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
          </template>

          <!-- scroll 영역 -->
          <template #scroll>
            <!-- 조회결과 리스트 -->
            <ur-box-container v-if="!isEmptyView" alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">
              <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-accodian ns-style2">
                <mo-list :list-data="cmCccObjrCmpxSVO">
                  <template #list-item="{ index, item }">
                    <mo-list-item expandable prevent-expand :class="{'on' : item.checked === true}" @click.native.stop="fn_OnClickListItem(index)">
                      <mo-checkbox v-model="item.checked" @input="fn_CheckSelectedItemCount(item)" :disabled="checkDisabled" :ref="`chk${index}`"></mo-checkbox>
                      <div class="list-item__contents">
                        <!-- 고객명/회수제외 -->
                        <div class="list-item__contents__title">
                          <!-- 고객카드 없음 240129 추가 -->
                          <span class="name" :class="!item.chnlCustId.trim() ? 'need-cs-card' : null" @click.stop="fn_OpenCustInfoBs(item)">
                            {{ item.custNm }}
                          </span>
                          <!-- 고객카드 없음 240129 추가 -->
                          <mo-badge v-if="item.rmtchHestYmd" class="badge-sample-badge blue" text="회수제외" shape="status">회수제외</mo-badge>
                        </div>
                        <!-- 미터치상태/회수대상 -->
                        <div class="list-item__contents__info">
                          <div class="info_box">
                            <span>미터치 상태</span><span class="txtRed ml10">{{ item.touchstate }} / {{ item.ltmUnTuchWdrwObjYn }}</span>
                          </div>
                        </div>
                        <!-- 터치내용/최근터치일 -->
                        <div class="list-item__contents__info">
                          <div class="info_box">
                            <span>터치이력</span><span class="ml10">{{ item.custCntcIdCd }}</span><span class="ml10">{{ item.rntTuchYmd }}</span>
                          </div>
                        </div>
                      </div>
                      <template v-slot:expand class="ns-gray-box">
                        <div class="list-item-detail customer-interest">
                          <!-- 생년월일/성별/마케팅동의 -->
                          <div class="contents-row">
                            <div class="info-cell">
                              <span>{{ item.dob }}</span><em>|</em>
                              <span>{{ item.sxdsCdNm === '남자' ? '남' : '여' }}</span><em>|</em>
                              <span>{{ item.mktCnsntEndYmd ? '마동 Y' : '마동 N'}}</span>
                            </div>
                          </div>
                          <!-- 고객카드 YN -->
                          <div class="contents-row">
                            <div class="title-cell w100">
                              <span>고객카드</span>
                            </div>
                            <div class="value-cell">
                              <span>{{ item.custYn }}</span>
                            </div>
                          </div>
                          <!-- 인수일 -->
                          <div class="contents-row">
                            <div class="title-cell w100">
                              <span>인수일</span>
                            </div>
                            <div class="value-cell">
                              <span>{{ item.undtkYmd }}</span>
                            </div>
                          </div>
                          <!-- 회수제외신청 : 신청한 경우에만 노출 -->
                          <div class="contents-row table" v-if="item.rmtchHestYmd">
                            <div class="title-cell w100">
                              <span>회수제외신청</span>
                            </div>
                            <div class="value-cell">
                              <span>{{ item.rmtchHestYmd }}</span>
                            </div>
                          </div>
                        </div>
                      </template>
                    </mo-list-item>
                  </template>
                </mo-list>
              </ur-box-container>

              <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 : 리스트 존재시에만 적용 -->
              <div v-if="positionUp && cmCccObjrCmpxSVO.length > 0" class="ns-height120"></div>
              <div v-else-if="cmCccObjrCmpxSVO.length > 0" class="ns-height40"></div>

            </ur-box-container>

            <!-- NoData 영역  -->
            <ur-box-container v-else alignV="start" componentid="ur_box_container_004" direction="column" class="no-data">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__info">
                    <span>데이터가 없습니다.</span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>

            <!-- 개인정보 이용 및 활용 동의 팝업 -->
            <mo-bottom-sheet ref="bottomSheet_CustInfo" class="ns-bottom-sheet ns-style3">
              <template v-slot:title>고객카드 생성</template>
              <div class="customer-info-txt pt0">
                <strong class="name">{{ pObjmsg.custNm }}</strong>
                <p class="info">생년월일 
                  <span class="birth">{{ $bizUtil.dateDashFormat(pObjmsg.birthDay) }}</span><em>|</em>
                  <span>{{ pObjmsg.gender }}</span>
                </p>
                <p class="ment">
                  등록된 고객이 아닙니다.<br>
                  '고객카드 생성'버튼을 클릭하시면<br>
                  고객카드가 생성됩니다.<br>
                  (단, 필수컨설팅, 마케팅동의는 별도 수집 필요)
                </p>
              </div>
              <template v-slot:action>
                <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
                  <div class="relative-div">
                    <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Btn_C_OnClick()">취소</mo-button>
                    <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Btn_I_OnClick()">고객카드 생성</mo-button>
                  </div>
                </ur-box-container>
              </template>
            </mo-bottom-sheet>

            <!-- Toast -->
            <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar>

            <!-- 회수제외신청/취소 선택 팝업 -->
            <div :class="positionUp ? 'ns-bottom-sheet ns-style3 only-btn positionUp' : 'ns-bottom-sheet ns-style3 only-btn'">
              <mo-bottom-sheet ref="bottomSheet_ChHest">
                <template v-slot:action>
                  <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
                    <div class="relative-div">
                      <mo-button v-if="isChHestShow1" :disabled="isChHestDisabled" @click="fn_OnDefaultModal3('U')"
                        componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue">회수제외 신청</mo-button>
                      <mo-button v-if="isChHestShow2" :disabled="isChHestDisabled" @click="fn_OnDefaultModal3('D')"
                        componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue">회수제외 신청취소</mo-button>
                    </div>
                  </ur-box-container>
                </template>
              </mo-bottom-sheet>
            </div>

            <!-- 회수제외신청 확인 팝업 -->
            <mo-bottom-sheet ref="bottomSheet_rMtchHest" class="ns-bottom-sheet ns-style3">
              <template v-slot:title>회수제외 신청</template>
              <div class="customer-info-txt">
                <p v-if="rMtchHestFlag === 'U'" class="ment gray">
                  회수제외를 신청하시겠습니까?
                </p>
                <p v-else class="ment gray">
                  회수제외 신청을 취소하시겠습니까?
                </p>
              </div>
              <template v-slot:action>
                <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
                  <div class="relative-div">
                    <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_OnDefaultModal5('cancel')">아니요</mo-button>
                    <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_OnDefaultModal5('confirm')">예</mo-button>
                  </div>
                </ur-box-container>
              </template>
            </mo-bottom-sheet>

            <!-- 인수참여신청 취소 팝업 -->
            <mo-bottom-sheet ref="bottomSheet_confirmInsu" class="ns-bottom-sheet ns-style3">
              <template v-slot:title>인수참여신청</template>
              <div class="customer-info-txt">
                <p class="ment gray">
                  인수참여신청을 취소하시겠습니까? 취소하시면 이달 신규 관심고객이 제공되지 않습니다.
                </p>
              </div>
              <template v-slot:action>
                <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
                  <div class="relative-div">
                    <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CloseInsu()">취소</mo-button>
                    <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Insu('C')">확인</mo-button>
                  </div>
                </ur-box-container>
              </template>
            </mo-bottom-sheet>

          </template>
        </mo-collapsing-header-view>        
      </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import moment from 'moment'
import Msg from '@/systems/webkit/msg/msg'
import MSPCM116P from '@/ui/cm/MSPCM116P' // 수금인수고객 목록 검색
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCM320D',
  // 현재 화면 ID
  screenId: 'MSPCM320D',
  // 컴포넌트 선언
  components: {
    MSPCM116P,
  },
  props: {
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      swiperOption: {
        centerSlides: true,
        slidesPerView: 1,
        spaceBetween: 10,
        autoplay: {
          delay: 20000,
          disableOnInteraction: false, 
        }
      },
      lv_HeaderviewScrollCompID: '',

      isMngr: false, // 지점장 여부
      toastMsg: '', // 문자발송 toast 문구
      pOrgData320D: {}, // 최근 조직 조회 데이터

      // 공통코드 변수
      activityCd: [], // 최근터치내용

      // 체크 변수
      checkDisabled: false, // 체크박스 비활성화 여부
      isOneOrMoreCheck: false, // 전체선택
      selectItems: [], // 선택된 고객 list
      positionUp: false, // 회수제외신청 바텀시트 강제 여부

      // 조회 변수
      today: Number,
      cmCccObjrCmpxSVO: [], // 인피니트 바인딩 리스트VO
      lv_inputParam: {}, // 조회 Obj
      inputCustNm: '', // 고객명 입력값
      cccDtScCd: '00', // 인수 (default 전체)
      cccDtScCds: [], // 인수 OBJ
      isEmptyView: false, // 데이터 없음 화면
      lv_inputParamD: { // 서비스호출 파라미터(원거리회수)
        iCCccDistVO: []
      },
      lv_inputParamP: { // 서비스호출 파라미터(회수대상 유예신청)
        iCWdrwHestAplVO: []
      },
      rMtchHestFlag: '', // 회수제외신청/취소 flag
      rmtchHestAplFgr: '', // 회수제외신청 모수
      totCnt: 0, // 누적 총 조회건수
      isChHestShow1: true, // 회수제외신청
      isChHestShow2: false, // 회수제외취소
      isChHestDisabled: true, // 회수제외 신청여부

      // 더보기 key
      stndKeyList: [],
      stndKeyVal: 'start',
      pageRowCnt: '20', // 페이지 카운트
      isLoading: false, // 더보기 기능

      // 인수참여 변수
      addmInfoCntnt: '', // 인수대상인 경우, 인수자격
      hasUnTuch: false, // 회수대상유무
      isObj: false, // 인수대상여부
      hasPtpt: false, // 인수참여여부

      // 고객카드 생성 변수
      pObjmsg: { // 고객카드 생성 Obj
        cnsltNo: '', // 컨설턴트번호(ZA_CHNL_ID)
        custId: '', // 고객ID(PARTNER)
        custNm: '', // 고객명
        knb: '', // 주민번호
        birthDay: '', // 생년월일
        gender: '' // 성별
      },

      isSearched: false, // 조회이력 저장 변수
      isSetBanner: false, // 배너 세팅여부
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    checkCount () {
      return this.cmCccObjrCmpxSVO.filter(item => item.checked).length
    },
    topButtonBottom () {
      // topButton을 positionUp 클래스가 적용된 바텀시트의 위쪽으로 올라오게 함
      if (this.positionUp) return 150
      else return 0
    }
  },
  
  /** watch 정의 영역 */
  watch: {
    checkCount () {
      if (this.checkCount === this.cmCccObjrCmpxSVO.length && this.cmCccObjrCmpxSVO.length !== 0) {
        this.isOneOrMoreCheck = true
      } else {
        this.isOneOrMoreCheck = false
      }
    }
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    // 사용자 정보
    this.userInfo = this.getStore('userInfo').getters.getUserInfo
    this.today = Number(moment(new Date()).format('YYYYMMDD'))

    // 배너 세팅 여부(최초접근시만 가능)
    this.isSetBanner = false

    // 지점장 여부 확인 :: 해당 사용자는 본인이 아닌 FC번호로 조회가능
    if (this.$cmUtil.isSrchOrgCombo() === 'Y') {
      this.isMngr = true
    }

    // 공통코드 조회
    this.fn_CommCdList()
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 스크롤 애니메이션 instance 선언
    this.lv_HeaderviewScrollCompID = this.$refs.headerview?.getScrollElementId()
    document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)

    // page(tab) view count
    let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM317M

    if (pageCnt === 0) {
      // 최초접근(사용안함)
      window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM317M', -1)
    } else {
      // 관심고객 전체에서 넘어온 경우를 제외하곤 카운트 쌓지 않음
      if (pageCnt % 2 === -1) {
        window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM317M', pageCnt - 1)
      }
    }

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog('MSPCM320D')
  },

  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    fn_OnClickListItem(index) {
      if (this.checkDisabled) return
      this.$refs[`chk${index}`].onClick()
    },

    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack () {
      if (this.isSearched) {
        // 조회를 한번이라도 한 경우 전체 검색

        // 초기화
        this.pOrgData320D = {} // 조직 조회 데이터
        this.inputCustNm = '' // 고객명

        this.isSearched = false // 검색이력 초기화

        // 바텀시트 초기화
        if (this.$refs !== undefined) {
          if (this.$refs.bottomSheet_ChHest !== undefined) {
            this.positionUp = false
            this.$refs.bottomSheet_ChHest.close()
          }
        }

        // 재조회
        let flag = true
        let submit = true
        let getservice = false

        // 해당 설계사 인수대상여부/참여여부/자격 조회
        this.fn_SubmitBeforeS(flag, submit, getservice)
      } else {
        // 탭이 아닌 별도 페이지로 구성되어 store에서 누적 카운트를 한다.
        let cnt = window.vue.getStore('cmStore').getters.getState.viewCntCM317M
        window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM317M', 0)
        // 조회를 하지 않았으면 뒤로가기
        if (this.checkCount > 0) this.fn_confirm('', '', cnt)
        else this.$router.go(cnt)
      }
    },

    /******************************************************************************
     * Function명 : fn_CommCdList
     * 설명       : 공통코드 조회
     ******************************************************************************/
    fn_CommCdList () {
      let lv_Vm = this
      this.$commonUtil.selListMassSliCommCd(['TUCH_RNK_CD', 'CCC_DT_SC_CD', 'CUST_CNTC_ID_CD']).then(function (response) {
        let codeList1 = lv_Vm.getStore('code').getters.getState.get('CUST_CNTC_ID_CD')
        let codeList2 = lv_Vm.getStore('code').getters.getState.get('CCC_DT_SC_CD')

        if (codeList1 !== undefined && codeList1 !== null && codeList1.length !== 0) {
          if (codeList2 !== undefined && codeList2 !== null && codeList2.length !== 0) {
            // 최근터치내용 공통코드
            lv_Vm.fn_RetrieveUserData(codeList1, codeList2)
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_RetrieveUserData
     * 설명       : 최근터치내용 공통코드
     ******************************************************************************/
    fn_RetrieveUserData (codeList1, codeList2) {
      this.activityCd = codeList1 // 최근터치내용
      this.cccDtScCds = codeList2

      let flag = true
      let submit = true
      let getservice = false

      // 해당 설계사 인수대상여부/참여여부/자격 조회
      this.fn_SubmitBeforeS(flag, submit, getservice)
    },

    /******************************************************************************
     * Function명 : fn_SubmitBeforeS
     * 설명       : 해당 설계사 인수대상여부/참여여부/자격 조회
     ******************************************************************************/
    fn_SubmitBeforeS (flag, submit, getservice) {
      if (this.stndKeyVal === 'end') return
      const trnstId = 'txTSSCM54S3'// 인수자격 조회
      const auth = 'S'

      let lv_Vm = this
      let confirm = this.getStore('confirm')

      let pParams = {}
      pParams.cnsltNo = lv_Vm.userInfo.userId // 컨설턴트번호
      pParams.inqrScVal = 'S' // 조회구분

      // post 호출 전 Progress 타입 설정.
      window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            let objYn = response.body.objYn
            let ptptYn = response.body.ptptYn

            if (objYn === 'Y') {
              if (ptptYn === 'Y') {
                // 대상여부 y 참여여부 y
                lv_Vm.isObj = true
                lv_Vm.hasPtpt = true
              } else {
                // 대상여부 y 참여여부 n
                lv_Vm.isObj = true
                lv_Vm.hasPtpt = false
              }
              lv_Vm.addmInfoCntnt = response.body.addmInfoCntnt
            } else {
              // 대상여부 n 참여여부 n
              lv_Vm.isObj = false
              lv_Vm.hasPtpt = false
            }
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }

          if ( flag === true ) {
            if ( submit === true ) {
              lv_Vm.fn_SubmitS()
            } else if ( getservice === true ) {
              lv_Vm.fn_GetServiceS()
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명 : fn_Insu
     * 설명       : 관심고객 인수참여
     ******************************************************************************/
    fn_Insu (sendType) {
      let lv_Vm = this
      if (sendType === 'I' || sendType === 'C') { // 인수참여,취소
        const trnstId = 'txTSSCM54U3'
        const auth = 'S'

        let confirm = this.getStore('confirm')

        let pParams = {}
        pParams.cnsltNo = lv_Vm.userInfo.userId // 컨설턴트번호
        pParams.inqrScVal = sendType // 인수참여(I: 참여, C: 취소)

        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            // 서버 데이터 가져오기
            if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
              if (sendType === 'I') {
                lv_Vm.hasPtpt = true
              } else {
                lv_Vm.hasPtpt = false
              }

              // 인수 취소시 바텀시트 닫기
              if (sendType === 'C') {
                lv_Vm.$refs.bottomSheet_confirmInsu.close()
              }

              // 재조회
              let flag = false
              let submit = false
              let getservice = false
              lv_Vm.fn_SubmitBeforeS(flag, submit, getservice)
            } else {
              confirm.dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      }
    },

    /******************************************************************************
     * Function명 : fn_ConfInsu
     * 설명       : 관심고객 인수참여 취소 확인
     ******************************************************************************/
    fn_ConfInsu () {
      this.$refs.bottomSheet_confirmInsu.open()
    },

    /******************************************************************************
     * Function명 : fn_CloseInsu
     * 설명       : 관심고객 인수참여 취소 확인
     ******************************************************************************/
    fn_CloseInsu () {
      this.$refs.bottomSheet_confirmInsu.close()
    },

    /******************************************************************************
     * Function명 : fn_SubmitS
     * 설명       : 조회 버튼 클릭 이벤트
     ******************************************************************************/
    fn_SubmitS () {
      // 체크된 고객 초기화
      let selectItems_init = []
      this.$cmUtil.setSelectedCustList(this, selectItems_init)
      
      // user와 선택한 컨설턴트가 다를 경우 체크박스 비활성화
      if (this.pOrgData320D.cnslt !== undefined && this.isMngr === true) {
        if (this.userInfo.userId !== this.pOrgData320D.cnslt.key) {
          this.checkDisabled = true
        } else {
          this.checkDisabled = false
        }
      } else {
        // 초기 접근 시
        this.checkDisabled = false
      }

      this.totCnt = 0
      this.stndKeyVal = 'start' // 더보기 최초조회
      this.stndKeyList = [] // 초기로드 or 조회버튼시
      this.selectItems.splice(0, this.selectItems.length)
      this.cmCccObjrCmpxSVO = [] // 인피니트 바인딩 데이터
      this.isEmptyView = false // 데이터가 없습니다 visible 여부
      this.fn_DataSetS() // 조회 파라미터 세팅

      this.fn_GetServiceS() // 서비스 호출
    },

    /******************************************************************************
     * Function명 : fn_DataSetS
     * 설명       : 조회전 데이터 파라미터 세팅
     ******************************************************************************/
    fn_DataSetS () {
      // 지점장 조회시 구분
      if (this.pOrgData320D.cnslt !== undefined && this.isMngr === true) {
        this.lv_inputParam.cnsltNo  = this.pOrgData320D.cnslt.key // 선택한 컨설턴트 번호
      } else {
        this.lv_inputParam.cnsltNo  = this.userInfo.userId // 컨설턴트 번호
      }

      this.lv_inputParam.tuchRnkCd = '' // 터치순위코드
      this.lv_inputParam.undtkScCd = this.cccDtScCds[0].key // 인수코드 default
      this.lv_inputParam.custNm = this.inputCustNm // 고객명
      // 최근터치일 1년이상(회수대상) 조건
      this.lv_inputParam.rntTuchInqrStrYmd = ''
      this.lv_inputParam.rntTuchInqrEndYmd = ''
      this.lv_inputParam.ltmUnTuchWdrwObjYn = 'Y' // 회수유예대상코드(Y:대상)
    },

    /******************************************************************************
     * Function명 : fn_DataSetS
     * 설명       : 서비스 호출(관심고객_회수대상 목록 조회)
     ******************************************************************************/
    fn_GetServiceS () {
      if (this.stndKeyVal === 'end') return
      if (this.isLoading) return

      const lv_Vm = this
      // const trnstId = 'txTSSCM54S1'// 관심고객 목록 조회
      const trnstId = 'txTSSCM47S3'// 관심고객 목록 조회
      const auth = 'S'

      let pParams = this.lv_inputParam
      let confirm = this.getStore('confirm')
      this.isLoading = true

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            let responseList = response.body.iCCccObjrMoblVO

            lv_Vm.lv_inputParamD.cnsltNo = response.body.cnsltNo
            lv_Vm.lv_inputParamD.strYmd = response.body.orgnDstWdrwAplStrYmd
            lv_Vm.lv_inputParamD.endYmd = response.body.orgnDstWdrwAplEndYmd
            lv_Vm.lv_inputParamD.rMtchHestAplStrYmd = response.body.rmtchHestAplStrYmd
            lv_Vm.lv_inputParamD.rMtchHestAplEndYmd = response.body.rmtchHestAplEndYmd
            lv_Vm.lv_inputParamP.finYm = response.body.finYm
            lv_Vm.rmtchHestAplFgr = response.body.rmtchHestAplFgr

            if (responseList !== null) {
              for (let i = 0; i < responseList.length; i++) {
                if (responseList[i].orgnDstCustWdrwAplYn === 'Y') { // 두개가 동시에 "Y" 가 될 확률 없음
                  responseList[i].colStrType = '원거리 회수'
                } else {
                  responseList[i].colStrType = ''
                }

                let nowDate = lv_Vm.$bizUtil.getDateToString(new Date(), '') // 오늘
                let month1Date = lv_Vm.$bizUtil.getDateToString(new Date(moment(responseList[i].rntTuchYmd).add(+3, 'months')), '') // 최근터치일 3개월 경과
                let month6Date = lv_Vm.$bizUtil.getDateToString(new Date(moment(responseList[i].rntTuchYmd).add(+6, 'months')), '') // 최근터치일 6개월 경과
                let month12Date = lv_Vm.$bizUtil.getDateToString(new Date(moment(responseList[i].rntTuchYmd).add(+1, 'years')), '') // 최근터치일 12개월 경과

                if (nowDate > month1Date) {
                  responseList[i].touchstate = '3개월'
                }
                if (nowDate > month6Date) {
                  responseList[i].touchstate = '6개월'
                }
                if (nowDate > month12Date) {
                  responseList[i].touchstate = '12개월'
                }

                // 최근터치내용
                var activity_commCd = lv_Vm.activityCd.filter(varm => varm.cdVal === responseList[i].custCntcIdCd)
                if ( activity_commCd.length > 0) {
                  responseList[i].custCntcIdCd = activity_commCd[0].cdValNm
                }

                // 회수대상 초기화
                responseList[i].ltmUnTuchWdrwObjYn = ''

                // 회수대상-임의코딩
                // 최근터치일-12개월 => '대상'
                // 최근터치일-6개월 => '유의'
                // 최근터치일 공란, 인수일-12개월 => '대상'
                if (responseList[i].rntTuchYmd.trim() === '') {
                  if (responseList[i].undtkYmd.trim() !== '') {
                    // 인수일 12개월 경과
                    let undtkYmd12Date = lv_Vm.$bizUtil.getDateToString(new Date(moment(responseList[i].undtkYmd).add(+1, 'years')), '')
                    if ( nowDate > undtkYmd12Date) {
                      responseList[i].ltmUnTuchWdrwObjYn = '회수대상'
                      responseList[i].touchstate = '12개월'
                    }
                  } else {
                    responseList[i].ltmUnTuchWdrwObjYn = '회수대상'
                    responseList[i].touchstate = '12개월'
                  }
                } else {
                  if (responseList[i].touchstate === '12개월') {
                    responseList[i].ltmUnTuchWdrwObjYn = '회수대상'
                  } else if (responseList[i].touchstate === '6개월') {
                    responseList[i].ltmUnTuchWdrwObjYn = '유의'
                  }
                }

                // 고객카드 Y/N
                if (responseList[i].chnlCustId.trim() !== '') {
                  responseList[i].custYn = 'Y'
                } else {
                  responseList[i].custYn = 'N'
                }

                responseList[i].dob = lv_Vm.$commonUtil.dateDashFormat(responseList[i].dob)
                responseList[i].rntTuchYmd = lv_Vm.$commonUtil.dateDashFormat(responseList[i].rntTuchYmd) // 최근터치일
                responseList[i].mktCnsntEndYmd = lv_Vm.$commonUtil.dateDashFormat(responseList[i].mktCnsntEndYmd)
                responseList[i].undtkYmd = lv_Vm.$commonUtil.dateDashFormat(responseList[i].undtkYmd)
                responseList[i].rmtchHestYmd = lv_Vm.$commonUtil.dateDashFormat(responseList[i].rmtchHestYmd.trim())
                responseList[i].ck = false
                responseList[i].checked = false
              } // for

              // 더보기 키값
              lv_Vm.stndKeyList = response.trnstComm.stndKeyList
              if (lv_Vm.stndKeyList === null || lv_Vm.stndKeyList[0] === null || lv_Vm.stndKeyList[0].stndKeyVal === null || lv_Vm.stndKeyList[0].stndKeyVal.trim() === '') {
                // 다음페이지 데이터 존재안함(마지막 페이지)
                lv_Vm.stndKeyVal = 'end'
              } else {
                // 다음페이지 데이터 존재함
                lv_Vm.stndKeyVal = 'more'
              }

              if (lv_Vm.stndKeyVal !== 'start' ) { // 더보기
                for (let i = 0; i < responseList.length; i++) {
                  lv_Vm.cmCccObjrCmpxSVO.push(responseList[i])
                }
              } else { // 1페이지
                lv_Vm.cmCccObjrCmpxSVO = responseList
              }

              // 조회 총 카운트
              lv_Vm.totCnt = lv_Vm.cmCccObjrCmpxSVO.length

              if (responseList.length === 0) {
                lv_Vm.hasUnTuch = false // 회수대상유무
                lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
              } else {
                lv_Vm.hasUnTuch = true // 회수대상유무
                lv_Vm.isEmptyView = false // 데이터가 없습니다 visible false
              }

              // 배너 swiper 적용되지 않아 강제로 업데이트
              if (lv_Vm.$refs !== undefined && lv_Vm.$refs.swiper !== undefined) {
                lv_Vm.$refs.swiper.$forceUpdate()
              }
              if (lv_Vm.$refs !== undefined && lv_Vm.$refs.swiper1 !== undefined) {
                lv_Vm.$refs.swiper1.$forceUpdate()
              }
              if (lv_Vm.$refs !== undefined && lv_Vm.$refs.swiper2 !== undefined) {
                lv_Vm.$refs.swiper2.$forceUpdate()
              }

              // 배너 조정(최초 접근시만 적용)
              if (!lv_Vm.isSetBanner) {
                lv_Vm.isSetBanner = true // 배너세팅 완료 처리
                lv_Vm.$refs.headerview?.adjustHeight()
              }
            } else {
              lv_Vm.hasUnTuch = false // 회수대상유무
              lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
            }
          } else {
            lv_Vm.hasUnTuch = false // 회수대상유무
            lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          lv_Vm.hasUnTuch = false // 회수대상유무
          lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명 : fn_LoadingData
     * 설명       : 인피니트 로딩데이터
     ******************************************************************************/
    fn_LoadingData () {
      // 스크롤 유무로 실행되는 함수이기 때문에 조회된 결과가 있는 경우에만 호출하는 로직 적용
      if (!this.isLoading && this.cmCccObjrCmpxSVO.length > 0) {
        // let flag = true
        // let submit = false
        // let getservice = true
        // this.fn_SubmitBeforeS(flag, submit, getservice)

        // 더보기 시 전체체크 해제되어있으면 전체체크 해제 후 조회
        if (this.isOneOrMoreCheck && this.stndKeyVal !== 'end') {
          this.isOneOrMoreCheck = false
        }

        // post 호출 전 Progress 타입 설정.
        window.vue.getStore('progress').dispatch('PART')
        
        this.fn_GetServiceS()
      }
    },

    /******************************************************************************
     * Function명 : fn_CheckAll
     * 설명       : 조회목록 전체선택
     ******************************************************************************/
    fn_CheckAll () {
      this.$nextTick(() => {
        if (this.cmCccObjrCmpxSVO.length > 0) { // 리스트 존재시에만 작동
          for (let n in this.cmCccObjrCmpxSVO) {
            if (this.isOneOrMoreCheck) {
              this.cmCccObjrCmpxSVO[n].checked = true
            } else {
              this.cmCccObjrCmpxSVO[n].checked = false
            }
          }

          this.selectItems = [] // 초기화
          this.selectItems = this.cmCccObjrCmpxSVO.filter(item => item.checked)

          // 회수제외신청/취소/비활성화 버튼 컨트롤
          this.fn_CtrlChHest()

          // 회수제외 또는 원거리 회수를 신청한 고객을 선택시 회수제외신청 바텀시트 숨김
          this.fn_ShowChHest()

          // 체크박스 클릭 핸들러
          this.fn_CheckSelectedItemCount()
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_CheckSelectedItemCount
     * 설명       : 체크박스 클릭 핸들러
     *             - 선택한 리스트 카운트를 체크한다. 30명 이상 문자전송 불가
     ******************************************************************************/
    fn_CheckSelectedItemCount (custInfo) {
      this.selectItems = [] // 초기화
      this.selectItems = this.cmCccObjrCmpxSVO.filter(item => item.checked)

      // 회수제외신청/취소/비활성화 버튼 컨트롤
      this.fn_CtrlChHest()

      // 회수제외 또는 원거리 회수를 신청한 고객을 선택시 회수제외신청 바텀시트 숨김
      this.fn_ShowChHest()

      // 체크 시 BottomSheet 컨트롤
      // 공통 시트를 가리고 사용자 시트를 보여준다.
      this.$cmUtil.setSelectedCustList(this, this.selectItems)
    },

    /******************************************************************************
     * Function명 : fn_CtrlChHest
     * 설명       : 회수제외신청/취소/비활성화 버튼 컨트롤
     ******************************************************************************/
    fn_CtrlChHest () {
      let showCnt1 = 0
      let showCnt2 = 0
      for (let i = 0; i < this.checkCount; i++) {
        if (this.selectItems[i].rmtchHestYmd !== '') {
          showCnt1++
        }
        if (this.selectItems[i].rmtchHestYmd.trim() === '') {
          showCnt2++
        }
      }
      if (showCnt1 === this.checkCount) {
        // 회수제외취소
        this.isChHestShow1 = false // 회수제외신청
        this.isChHestShow2 = true // 회수제외취소
        this.isChHestDisabled = false
      } else {
        // 회수제외신청
        if (showCnt1 !== 0 && showCnt2 !== 0) {
          // 신청한 사람과 신청하지 않은 사람이 같이 선택될 때 버튼 비활성화
          this.isChHestShow1 = true
          this.isChHestShow2 = false
          this.isChHestDisabled = true
        } else {
          this.isChHestShow1 = true
          this.isChHestShow2 = false
          this.isChHestDisabled = false
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_ShowChHest
     * 설명       : 회수제외 또는 원거리 회수를 신청한 고객을 선택시 회수제외신청 바텀시트 숨김
     ******************************************************************************/
    fn_ShowChHest () {
      let openBottomSheet = true
      let isColStrType = 0 // 원거리회수신청
      let isRmtchHestYmd = 0 // 회수제외신청
      isColStrType = this.selectItems.filter(item => item.orgnDstCustWdrwAplYn === 'Y').length
      isRmtchHestYmd = this.selectItems.filter(item => item.rmtchHestYmd !== '').length

      if (this.checkCount > 0) {
        // 선택한 고객 중 원거리회수신청고객 존재시 바텀시트 닫음
        if (isColStrType > 0) {
          openBottomSheet = false
        }

        // 선택한 고객 중 회수제외신청고객 존재시 바텀시트 닫음
        if (isRmtchHestYmd > 0 && isRmtchHestYmd !== this.checkCount) {
          openBottomSheet = false
        }

        if (openBottomSheet) {
          this.positionUp = true
          this.$refs.bottomSheet_ChHest.open()
        } else {
          this.positionUp = false
          this.$refs.bottomSheet_ChHest.close()
        }
      } else {
        this.positionUp = false
        this.$refs.bottomSheet_ChHest.close()
      }
    },

    /******************************************************************************
     * Function명 : fn_OpenInsCustInfo
     * 설명       : 고객카드 생성 팝업
     ******************************************************************************/
    fn_OpenInsCustInfo (custInfo) {
      if (custInfo.rrn === undefined) {
        this.toastMsg = '생년월일이 없습니다.'
        this.$refs.snackbar.show({ text: this.toastMsg })
      } else {
        this.pObjmsg = {
          cnsltNo: this.lv_inputParam.cnsltNo, // 컨설턴트번호(ZA_CHNL_ID)
          custId: custInfo.custId, // 고객ID(PARTNER)
          custNm: custInfo.custNm, // 고객명
          knb: custInfo.rrn, // 주민번호
          birthDay: this.$bizUtil.addBirthFrontYear(custInfo.rrn.substring(0, 6)), // 생년월일
          gender: (custInfo.sxdsCdNm !== undefined) ? custInfo.sxdsCdNm : this.fn_GetSxdsNmByRrn(custInfo) // 성별
        }

        // 고객카드생성 바텀시트 오픈
        this.$refs.bottomSheet_CustInfo.open()
      }
    },

    /******************************************************************************
     * Function명 : fn_GetSxdsNmByRrn
     * 설명       : 주민번호로 성별 구하기
     ******************************************************************************/
    fn_GetSxdsNmByRrn (custInfo) {
      let rtnVal = ''
      let sexDist = custInfo.rrn.substring(6,7)

      if (sexDist === '1' || sexDist === '3' || sexDist === '5' || sexDist === '7') {
        rtnVal = '남'
      } else if (sexDist === '2' || sexDist === '4' || sexDist === '6' || sexDist === '8') {
        rtnVal = '여'
      }

      return rtnVal
    },

    /******************************************************************************
     * Function명 : fn_Btn_C_OnClick
     * 설명       : 고객카드생성 취소 버튼
     ******************************************************************************/
    fn_Btn_C_OnClick () {
      // 고객카드생성 바텀시트 닫기
      this.$refs.bottomSheet_CustInfo.close()
    },

    /******************************************************************************
     * Function명 : fn_Btn_I_OnClick
     * 설명       : 고객카드생성 확인 버튼
     ******************************************************************************/
    fn_Btn_I_OnClick () {
      // 지점장 권한일 경우 고객카드생성 불가
      if (this.isMngr !== true) {
        this.fn_InsCustInfo()
      }
    },

    /******************************************************************************
     * Function명 : fn_InsCustInfo
     * 설명       : 통합고객 정보를 채널고객으로 등록
     ******************************************************************************/
    fn_InsCustInfo () {
      const lv_Vm = this
      const trnstId = 'txTSSCM55I1'
      const auth = 'I'

      let confirm = lv_Vm.getStore('confirm')
      let iCNewCustRegVO = lv_Vm.fn_SET_OCC() // 고객 저장 데이터 셋팅
      let pParams = iCNewCustRegVO

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
        // 서버 데이터 가져오기
          if (response.body !== null && response.body !== '' && response.body.chnlCustId !== null && response.body.chnlCustId !== '') {
            // 고객카드 저장 완료 후 바텀시트 닫고
            lv_Vm.$refs.bottomSheet_CustInfo.close()
            lv_Vm.positionUp = false
            lv_Vm.$refs.bottomSheet_ChHest.close()
            lv_Vm.$refs.bottomSheet_rMtchHest.close()
            lv_Vm.$refs.bottomSheet_confirmInsu.close()
            // 리스트 재조회
            let flag = true
            let submit = true
            let getservice = false
            lv_Vm.fn_SubmitBeforeS(flag, submit, getservice)
            // 네임UI 오픈
            let custInfo = {
              chnlCustId: response.body.chnlCustId,
              custNm: ''
            }
            lv_Vm.fn_OpenCustInfoBs(custInfo)
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }

        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명 : fn_SET_OCC
     * 설명       : 고객 저장 데이터 셋팅
     ******************************************************************************/
    fn_SET_OCC () {
      let param = {}

      param.prcsfCd = 'I'
      param.condWhlCnt = 0
      param.cnsltNo = this.pObjmsg.cnsltNo
      param.custId = this.pObjmsg.custId
      param.custNm = this.pObjmsg.custNm
      param.knb = this.pObjmsg.knb
      param.busnTrtPathCd = '2220' // 업무처리경로코드 (태블릿_사랑온고객관리 : 2220) *** 신태블릿용 추가 검토 필요
      param.trtSystmPathCd = '22' // 처리시스템경로코드(사랑온 고정 구분값 : 22) *** 신태블릿용 추가 검토 필요
      param.custDtlTypCd = this.fn_USR_getNatyGb(this.pObjmsg.knb) // 고객상세유형코드 셋팅

      if (this.pObjmsg.knb.length > -6) {
        param.chnlCustScCd = '01'
        param.ralDob = this.$bizUtil.addBirthFrontYear(this.pObjmsg.knb.substring(0, 6))
      } else {
        param.chnlCustScCd = '02'
        param.ralDob = ' '
      }

      return param
    },

    /******************************************************************************
     * Function명 : fn_USR_getNatyGb
     * 설명       : 고객상세유형코드 셋팅
     ******************************************************************************/
    fn_USR_getNatyGb (knb) {
      var natyGb = '11'
      if (knb.length < 7) {
        return natyGb
      }
      var knbGb = knb.substring(6, 7)

      if (knbGb === '1' || knbGb === '2' || knbGb === '3' || knbGb === '4') {
        natyGb = '11'
      } else if (knbGb === '5' || knbGb === '6' || knbGb === '7' || knbGb === '8') {
        natyGb = '12'
      }
      return natyGb
    },

    /******************************************************************************
     * Function명 : fn_OnDefaultModal3
     * 설명       : 회수제외신청/취소 선택
     ******************************************************************************/
    fn_OnDefaultModal3 (str) {
      this.rMtchHestFlag = str
      let startD = Number(this.lv_inputParamD.rMtchHestAplStrYmd)
      let endD = Number(this.lv_inputParamD.rMtchHestAplEndYmd)
      let conCheck = false

      // if (!(this.today >= startD && this.today <= endD)) { // pass
      if (this.today >= startD && this.today <= endD) { // 원본
        if (this.rMtchHestFlag === 'U') {
          for (let i in this.selectItems) {
            if (this.selectItems[i].orgnDstCustWdrwAplYn === 'Y') {
              this.getStore('confirm').dispatch('ADD', '원거리회수신청 취소 후 회수제외신청바랍니다.')
              conCheck = true
              break
            }
            if (this.selectItems[i].rmtchHestYmd !== '') {
              this.getStore('confirm').dispatch('ADD', '신청 고객과 미신청 고객이 같이 선택될 수 없습니다')
              conCheck = true
              break
            }
          }
          if (conCheck) {
            return
          } else {
            let selCnt = Number(this.rmtchHestAplFgr) + Number(this.selectItems.length)
            if (selCnt > 20) {
              this.getStore('confirm').dispatch('ADD', '이번 달 회수제외 가능 고객수가 초과되었습니다 (' + selCnt + '/20)')
              return
            }
          }
        } else {
          for (let i in this.selectItems) {
            if (this.selectItems[i].rmtchHestYmd === '') {
              this.getStore('confirm').dispatch('ADD', '신청 고객과 미신청 고객이 같이 선택될 수 없습니다')
              conCheck = true
              break
            }
          }
          if (conCheck) {
            return
          }
        }
      } else {
        this.toastMsg = '회수제외 신청기간이 아닙니다'
        this.$refs.snackbar.show({ text: this.toastMsg })
        return
      }

      // 회수제외신청/취소 확인 바텀시트 오픈
      this.$refs.bottomSheet_rMtchHest.open()
    },

    /******************************************************************************
     * Function명 : fn_OnDefaultModal5
     * 설명       : 회수제외신청/취소 확인 바텀시트
     ******************************************************************************/
    fn_OnDefaultModal5 (str) {
      if (str === 'confirm') {
        this.fn_DataSetU()
        this.fn_GetServiceU(this.rMtchHestFlag)
      } else {
        // 취소 버튼
        this.$refs.bottomSheet_rMtchHest.close()
      }
    },

    /******************************************************************************
     * Function명 : fn_DataSetU
     * 설명       : 회수제외신청 전 데이터 파라미터 세팅
     ******************************************************************************/
    fn_DataSetU () {
      this.lv_inputParamP.iCWdrwHestAplVO = []
      let cnt = this.selectItems.length

      for (let i = 0; i < cnt; i++) {
        let saveObj = {}
        // 지점장 조회시 구분
        if (this.pOrgData320D.cnslt !== undefined && this.isMngr === true) {
          saveObj.cnsltNo = this.pOrgData320D.cnslt.key // 컨설턴트번호
        } else {
          saveObj.cnsltNo = this.lv_inputParam.cnsltNo
        }
        saveObj.custId = this.selectItems[i].custId
        saveObj.finYm = this.lv_inputParamP.finYm
        this.lv_inputParamP.iCWdrwHestAplVO.push(saveObj)
      }
    },

    /******************************************************************************
     * Function명 : fn_GetServiceU
     * 설명       : 서비스 호출(회수제외신청)
     ******************************************************************************/
    fn_GetServiceU (type) {
      let trnstId = 'txTSSCM54U4'// 회수제외신청
      if (type === 'D') {
        trnstId = 'txTSSCM54U5'// 회수제외취소
      }
      const auth = 'S'

      let lv_Vm = this
      let pParams = this.lv_inputParamP
      let confirm = this.getStore('confirm')

      this.isLoading = true
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          lv_Vm.isLoading = false

          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            if (type === 'D') {
              lv_Vm.toastMsg = '회수제외 신청 취소가 완료되었습니다.'
            } else {
              lv_Vm.toastMsg = '회수제외 신청이 완료되었습니다.'
            }
            lv_Vm.$refs.snackbar.show({ text: lv_Vm.toastMsg })

            // 바텀시트 초기화
            lv_Vm.positionUp = false
            lv_Vm.$refs.bottomSheet_ChHest.close()
            lv_Vm.$refs.bottomSheet_rMtchHest.close()

            lv_Vm.fn_SubmitS() // 재조회
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /*********************************************************
     * Function명: fn_OpenMSPCM116P
     * 설명: 고객명 검색 팝업 호출
     *********************************************************/
    fn_OpenMSPCM116P () {
      this.popup116 = this.$bottomModal.open(MSPCM116P, {
        properties: {
          pPage: 'MSPCM320D',
          pOrgData: this.pOrgData320D, // 최근 조직 조회 데이터
          pSearchKeyword: this.inputCustNm, // 고객명
          pIsMngr: this.isMngr, // 지점장 여부
          pCccDtScCd: this.cccDtScCds[0].key, // 인수(default 전체)
          pLstUnTuchPrdCd: '4' // 최근터치일(default 1년이상(회수대상))
        },
        listeners: {
          onPopupSearch: (pData) => {
            // 모달 닫기
            this.positionUp = false
            this.$refs.bottomSheet_ChHest.close()
            this.$bottomModal.close(this.popup116)

            this.inputCustNm = pData.rtnData.searchKeyword // 고객명

            // 마지막 조직 조회 데이터 셋팅
            this.pOrgData320D = pData.rtnData.pOrgData

            // 조회이력 저장
            this.isSearched = true

            // 재조회
            this.fn_SubmitS()
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_OpenCustInfoBs
     * 설명       : 고객상세 팝업 연동
     ******************************************************************************/
    fn_OpenCustInfoBs (item) {
      let lv_Vm = this
      let cnsltNo = ''
      let nmUiObject = {}

      // 채널고객ID가 없을 경우 공통이 아닌 화면별 고객등록동의 팝업 표시
      if(this.$bizUtil.isEmpty(item.chnlCustId)) {
        this.fn_OpenInsCustInfo(item)
        return
      }
      
      if(this.pOrgData320D.cnslt === undefined || this.pOrgData320D.cnslt === null || this.pOrgData320D.cnslt === ''){
        cnsltNo = this.userInfo.userId
      }else{
        cnsltNo = this.pOrgData320D.cnslt.key
      }

      nmUiObject = {
        chnlCustId : item.chnlCustId, //고객채널 ID
        parentId   : 'MSPCM320D', //화면ID+
        cnsltNo : cnsltNo,
        custNm : item.custNm
      }

      // window.vue.getStore('progress').dispatch('FULL')
      lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
         //Close 콜백 처리
         return
      })
    },

    /******************************************************************************
     * @description confirm popup
     * @param {String} title 제목
     * @param {String} message confirm 내용
     ******************************************************************************/
    fn_confirm(title, message, cnt) {
      let lv_Vm = this
      let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: backMsg,
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
              lv_Vm.$router.go(cnt)
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },

  }
}
</script>
